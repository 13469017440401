<template>
	<section class="section-wrapper" :class="value.type">
		<grid-template :cols="value.type==='product' ? '5' : '3'" :className="`gallery ${value.type}`" :class="`${value.type}`">
			<template #title>
				<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`" v-animate="`slideLeft`"/>
			</template>
			<figure v-for="(item,id) in value.card_schema" :key="id" class="gallery-image" @click="toggle(id)" ref="imageWrapper" v-animate="`opacity`">
				<cms-image
					lazy
					:src="item.image"
					:base="{width: 360}"
					:xl="{height:  value.type==='product' ? 520 : 262}"
				/>
			</figure>
		</grid-template>
		<realizations-gallery-modal v-if="isModalOpen" :type="value.type" :images="value.card_schema" :activeImageIndex="activeImageIndex">
			<button-close @close="toggle" />
		</realizations-gallery-modal>
	</section>
</template>
<script>
import ButtonClose from '~/website/front/components/molecules/ButtonClose/ButtonClose.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import RealizationsGalleryModal from '~/website/front/components/organisms/RealizationsGalleryModal/RealizationsGalleryModal.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'

export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		GridTemplate,
		BaseHeading,
		RealizationsGalleryModal,
		ButtonClose
	},
	data: () => ({
		activeImageIndex: 0,
		isModalOpen: false
	}),
	computed: {
		activeImageSrc () {
			return this.value.card_schema[this.activeImageIndex].image
		}
	},
	methods: {
		toggle (id) {
			this.activeImageIndex = id
			this.isModalOpen = !this.isModalOpen
		},
		slideAnimation () {
			this.$refs.imageWrapper.animate([
				{ opacity: 0 }, { opacity: 1 }
			], 1000)
		}
	}

}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0 4rem 0;
    @include media-breakpoint-up(sm) {
        padding: 4rem 0 0 0;
    }
	@include media-breakpoint-up(xl) {
		padding: 8rem 0 0 0;
        overflow: hidden;
	}
	::v-deep .gallery {
		grid-auto-rows: 262px;
        @include media-breakpoint-landscape {
            grid-auto-rows: 150px;
        }
        &.product {
        grid-auto-rows: 350px;
        @include media-breakpoint-landscape {
            grid-auto-rows: 470px;
        }
        .gallery-image .base-image {
            object-fit: contain;
        }
        @include media-breakpoint-up(md) {
            .gallery-image .base-image {
                object-fit: cover;
            }
        }

        @include media-breakpoint-up(xl) {
             grid-auto-rows: 400px;
        }
        @include media-breakpoint-up(xxl) {
            grid-auto-rows: 500px;
        }
    }
		.gallery-image {
			margin: 0;
			cursor: pointer;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $black;
				opacity: 0;
				transition: .3s ease-in;
			}
			@include media-breakpoint-up(xl) {
				&:hover {
					&::after {
						opacity: .3;
					}
				}
			}

			.base-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			&:nth-of-type(n + 4) {
				display: none;
				@include media-breakpoint-up(xl) {
					display: block;
				}
			}
		}
	}
}

</style>
