<template>
	<div class="realizations-slider-modal">
		<div class="realizations-modal">
			<div class="close">
				<slot/>
			</div>
			<div class="thumbnails">
				<figure
					class="thumbnail"
					:class="{active: i === index}"
					v-for="(image, i) in images"
					:key="image.path"
					@click="goTo(i)"
				>
					<cms-image
						class="slide-realizations-img"
						:src="image.image"
						:base="{height: 45, width: 45}"
					/>
				</figure>
			</div>
			<figure class="img-wrapper" v-if="this.type==='product'">
				<cms-image
					class="slide-realizations-img landscape-image"
					:src="activeImageSrc"
					:base="{ height: 650}"
                    :md="{height: 1200, width: 600}"
					:lg="{height: 600}"
					:xxl="{height: 800}"
				/>
			</figure>
            <figure class="img-wrapper" v-else>
				<cms-image
					class="slide-realizations-img landscape-image"
					:src="activeImageSrc"
					:base="{ height: 650}"
					:lg="{height: 600}"
					:xxl="{height: 800}"
				/>
			</figure>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			index: this.activeImageIndex
		}
	},
	props: {
		images: Array,
		activeImageIndex: Number,
		type: {
			type: String,
			default: 'realization'
		}
	},
	computed: {
		activeImageSrc () {
			return this.images[this.index].image
		}
	},
	methods: {
		nextImage () {
			this.goTo(this.index + 1)
		},
		prevImage () {
			this.goTo(this.index - 1)
		},
		goTo (index) {
			this.index = (index + this.images.length) % this.images.length
		}
	}
}
</script>

<style lang="scss" scoped>
.realizations-modal {
	position: relative;
	background-color: $white;
	gap: 5px;
	max-height: 85vh;
	padding: 1rem;
	display: flex;
	max-width: 95%;
    @include media-breakpoint-landscape {
        max-width: 65%;
    }
	@include media-breakpoint-up(lg) {
		max-width: 80%;
		gap: 10px;
	}
	@include media-breakpoint-up(xl) {
		gap: 20px;
	}
	.thumbnails {
		display: flex;
		max-height: 100%;
		flex-direction: column;
		gap: 5px;
	}
	.thumbnail {
		position: relative;
		cursor: pointer;
		margin: 0;
		flex: 0 1 auto;
		min-height: 0;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($black, 0.35);
				opacity: 0;
				@include transition;
			}
			&.active {
				&::before {
					opacity: 1;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			&:hover {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	.close {
		position: absolute;
		top: -6rem;
		right: -25px;
        @include media-breakpoint-up(xl) {
            top: -5rem;
            right: -70px;
        }
        @include media-breakpoint-landscape {
            top: -4rem;
            right: -50px;
        }
		::v-deep .svg-icon {
			path {
				stroke: $white;
			}
		}
	}
	.img-wrapper {
		margin: 0;
		::v-deep .base-image {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.realizations-slider-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: $level2;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);

	::v-deep .landscape-image img {
		@media screen and (max-height: map-get($grid-breakpoints, sm)) and (orientation: landscape){
			height: 100vh;
		}
	}
}
</style>
